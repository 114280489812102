.modal-detail-mobile {
  max-width: 355px !important;
}
.modal-detail-mobile .ant-modal-content {
  background-color: #1E1F23;
  padding: 0;
  border-radius: 6px;
}
.modal-detail-mobile .ant-modal-header {
  background: #383A42;
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 18px;
}
.modal-detail-mobile .ant-modal-title {
  color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
  font-family: Poppins;
  font-size: 15.751px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.466px; /* 218.82% */
}
.modal-detail-mobile .ant-modal-close {
  border-radius: 6.893px;
  border: 0.862px solid var(--stroke-25-button, #55565E);
  background: var(--Control-Active, rgba(248, 248, 248, 0.05));
  background-blend-mode: luminosity;
  box-shadow: 0px 20.68px 20.68px 0px rgba(0, 0, 0, 0.30);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 9px;
}
.modal-detail-mobile .ant-modal-close-x {
  color: #fff;
}